import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.debugAllowed}}\n  <div\n    class=\"absolute right-0 top-0 overflow-y-auto rounded-bl-md bg-dusty-black-500 bg-opacity-50\"\n  >\n    <div class=\"relative\">\n      <button\n        class=\"ml-auto block rounded-bl-md bg-dusty-black-400 bg-opacity-80 px-3 py-2 text-white shadow-sm\"\n        data-test-toggle-debug=\"true\"\n        {{on \"click\" this.toggleDebug}}\n        type=\"button\"\n      >\n        {{this.buttonText}}\n      </button>\n\n      {{#if this.debugMode.enabled}}\n        {{yield}}\n      {{/if}}\n    </div>\n  </div>\n{{/if}}", {"contents":"{{#if this.debugAllowed}}\n  <div\n    class=\"absolute right-0 top-0 overflow-y-auto rounded-bl-md bg-dusty-black-500 bg-opacity-50\"\n  >\n    <div class=\"relative\">\n      <button\n        class=\"ml-auto block rounded-bl-md bg-dusty-black-400 bg-opacity-80 px-3 py-2 text-white shadow-sm\"\n        data-test-toggle-debug=\"true\"\n        {{on \"click\" this.toggleDebug}}\n        type=\"button\"\n      >\n        {{this.buttonText}}\n      </button>\n\n      {{#if this.debugMode.enabled}}\n        {{yield}}\n      {{/if}}\n    </div>\n  </div>\n{{/if}}","moduleName":"fast-phonics-client/components/debug-app/toggle-debug.hbs","parseOptions":{"srcName":"fast-phonics-client/components/debug-app/toggle-debug.hbs"}});
import { inject as service } from '@ember/service'
import { action } from '@ember/object'
import Component from '@glimmer/component'
import type DebugModeService from 'fast-phonics-client/services/debug-mode'

/**
 * Provide a button to toggle debug mode globally
 */
export default class ToggleDebug extends Component {
  @service declare debugMode: DebugModeService

  get debugAllowed() {
    return this.debugMode.debugAllowed
  }

  get buttonText() {
    if (this.debugMode.enabled) {
      return 'Disable Debug Mode'
    }
    return 'Enable Debug Mode'
  }

  @action
  toggleDebug() {
    if (this.debugMode.enabled) {
      this.debugMode.disable()
    } else {
      this.debugMode.enable()
    }
  }
}
