import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import { UnauthorizedError } from '@ember-data/adapter/error'
import {
  isErrorRoute,
  isInsideInteractive,
} from 'fast-phonics-client/utils/app-refresher-logic'
import type ActivityService from 'fast-phonics-client/services/activity'
import type ErrorHandlerService from 'fast-phonics-client/services/error-handler'
import type RouterService from '@ember/routing/router-service'
import type SessionService from 'fast-phonics-client/services/session'
import type Store from '@ember-data/store'
import type ReleaseChecker from '@blakeelearning/app-refresher/release-checker/service'
import type RefresherService from '@blakeelearning/app-refresher/refresher/service'
import type MetricsService from 'ember-metrics'
import type LongSessionKiller from '@blakeelearning/app-refresher/long-session-killer/service'
import type SessionTracker from '@blakeelearning/app-refresher/session-tracker/service'
import type Transition from '@ember/routing/-private/transition'
import type QuestService from 'fast-phonics-client/services/quest'

/**
 * The application router makes sure the session is loaded before sub-routes
 * are able to do things, so they and their controllers can all assume that a
 * session with a student model property will always exist.
 */

export default class ApplicationRoute extends Route {
  @service declare activity: ActivityService

  @service declare errorHandler: ErrorHandlerService

  @service declare longSessionKiller: LongSessionKiller

  @service declare metrics: MetricsService

  @service declare refresher: RefresherService

  @service declare releaseChecker: ReleaseChecker

  @service declare router: RouterService

  @service declare session: SessionService

  @service declare sessionTracker: SessionTracker

  @service declare store: Store

  @service declare quest: QuestService

  constructor(...args: ConstructorParameters<typeof Route>) {
    super(...args)

    // This will track the initial page load, after which we depend on
    // `trackPage` events being emitted.
    this.router.one('routeDidChange', () => {
      this._trackPage()
    })
    this.on('trackPage', () => {
      this._trackPage()
    })
  }

  override async beforeModel(transition: Transition): Promise<void> {
    if (transition.to?.name === 'login' || transition.to?.name === 'logout') {
      return
    }

    try {
      await this._setupSession()
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        // The student is apparently not logged in - this is a common
        // occurrence, most easily fixed by redirecting to the
        // fast_phonics_api /login URL.
        void this.router.transitionTo('login')
      } else {
        throw error
      }
    }
  }

  /**
   * Route error handlers are called synchronously so cannot return a promise
   * which also means we can't use async/await
   */
  @action
  override error(error: Error, transition?: Transition): true {
    const routeName = transition?.to?.name ?? 'unknown route'
    this.errorHandler.logErrorIfActionable(
      `Failed to transition to ${routeName}`,
      error,
    )
    return true
  }

  @action
  override willTransition(transition: Transition): void {
    this._scheduleAppRefreshOnSuccessfulTransition(transition)
  }

  _trackPage() {
    this.metrics.trackPage({
      page: this.router.currentURL,
      title: this.router.currentRouteName,
    })
  }

  async _setupSession(): Promise<void> {
    await Promise.all([this.session.setStudent(), this.activity.load()])

    await this.quest.fetch()

    const { student } = this.session

    // Start all of the app refresher services
    this.sessionTracker.start(student.id)
    this.releaseChecker.start()
    this.longSessionKiller.start()
  }

  /**
   * This is the fast-phonics-client logic for scheduling and executing app refreshes.
   * It is based on a transition rather than a route, because the determination
   * needs to consider both where the transition is coming from and where it is
   * going to.  At present there are two situations where we consider a refresh
   * unsafe:
   *
   * 1. when a transition occurs without changing the interactive (e.g.
   *    transitioning to the stones view of the peaks interactive); and
   * 2. when the destination route is an error page, because the error
   *    (whatever it is) might prevent the refresh from working correctly.
   */
  _scheduleAppRefreshOnSuccessfulTransition(transition: Transition): void {
    const fromRouteName = transition.from?.name

    void transition.followRedirects().then((toRoute) => {
      if (toRoute instanceof Route) {
        const { routeName: toRouteName } = toRoute
        // Prevent refresh if these conditions apply
        if (
          isInsideInteractive(toRouteName, fromRouteName) ||
          isErrorRoute(toRouteName)
        )
          return

        const willRefresh =
          this.refresher.refreshIfSafeAndScheduled(toRouteName)
        if (!willRefresh) this.trigger('trackPage')
      }
    })
  }
}
