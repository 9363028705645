import Controller from '@ember/controller'
import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import type Store from '@ember-data/store'
import { contentError } from 'fast-phonics-client/utils/errors'
import { AbortError, ServerError } from '@ember-data/adapter/error'
import type RouterService from '@ember/routing/router-service'
import type SessionService from 'fast-phonics-client/services/session'
import type ErrorHandlerService from 'fast-phonics-client/services/error-handler'
import type DebugModeService from 'fast-phonics-client/services/debug-mode'
import type PlacementTestResultModel from 'fast-phonics-client/models/placement-test-result'
import type LoadingUiService from 'fast-phonics-client/services/loading-ui'
import type ProgressService from 'fast-phonics-client/services/progress'
import type { PlacementTestContentActions } from '@blakeelearning/content-specs-fast-phonics'
import type MetricsService from 'ember-metrics'
import type QuestService from 'fast-phonics-client/services/quest'

// eslint-disable-next-line ember/no-test-import-export
export default class PlacementTestController extends Controller {
  @service declare metrics: MetricsService

  @service declare session: SessionService

  @service declare router: RouterService

  @service declare loadingUi: LoadingUiService

  @service declare errorHandler: ErrorHandlerService

  @service declare progress: ProgressService

  @service declare debugMode: DebugModeService

  @service declare store: Store

  @service declare quest: QuestService

  get activityComponent(): string {
    if (this.debugMode.enabled) {
      return 'debug-mode/placement-test'
    }
    return 'content-interactive'
  }

  get contentActions(): PlacementTestContentActions {
    return {
      unhandledError: ({ error }: { error: unknown }) => {
        this.errorHandler.handleContentUnhandledError(error)
      },
      goToPeaks: () => {
        this.goToPeaks()
      },
      goBack: () => {
        this.goBack()
      },
      skipPlacementTest: (data) => this.skipPlacementTest(data),
      saveTestResults: (data) => this.saveTestResults(data),
      readyForUserInput: () => {
        this.loadingUi.finish()
      },
    }
  }

  @action goToPeaks(): void {
    void this.router.transitionTo('peaks')
  }

  @action goBack(): void {
    this.metrics.trackEvent({
      category: 'Placement Test',
      action: 'Go Back',
      label: 'Go Back',
    })

    void this.router.transitionTo('logout')
  }

  _validateSubmission(blob: Blob): { ok: true } | { ok: false; error: string } {
    const isValid = blob.type === 'audio/wav'
    if (isValid) {
      return { ok: true }
    }
    return {
      ok: false,
      error: `Audio Type cannot be empty or of type other that audio/wav`,
    }
  }

  @action async skipPlacementTest(data: { reason: string }): Promise<void> {
    this.metrics.trackEvent({
      category: 'Placement Test',
      action: 'Skip Placement Test',
      label: data.reason,
    })

    const { student } = this.session
    await this._savePlacementResultResult({
      student,
      status: data.reason,
      skippedTest: true,
    })
  }

  @action async saveTestResults(data: {
    questionNumber: number
  }): Promise<{ peakNumber: number }> {
    this.metrics.trackEvent({
      category: 'Placement Test',
      action: 'Save Test Results',
      label: `Question Number ${data.questionNumber.toFixed()}`,
    })

    const { student } = this.session

    await this._savePlacementResultResult({
      student,
      questionNumber: data.questionNumber,
      status: 'complete',
    })

    const peakNumber = this.progress.lastAccessiblePeak

    return { peakNumber }
  }

  async _savePlacementResultResult(
    data: Record<string, unknown>,
  ): Promise<PlacementTestResultModel> {
    data['testType'] = 'text'

    const record = this.store.createRecord('placement-test-result', { ...data })
    try {
      await record.save()
    } catch (error) {
      record.unloadRecord()
      if (error instanceof AbortError) {
        throw contentError('NetworkError', error)
      } else if (error instanceof ServerError) {
        throw contentError('ServerError', error)
      } else {
        throw error
      }
    }

    await this.quest.fetch()

    return record
  }
}

declare module '@ember/controller' {
  interface Registry {
    'placement-test': PlacementTestController
  }
}
