import ApplicationAdapter from 'fast-phonics-client/adapters/application'
import type ModelRegistry from 'ember-data/types/registries/model'

export default class StudentAdapter extends ApplicationAdapter {
  override urlForQueryRecord(
    query: Record<string, unknown>,
    modelName: keyof ModelRegistry,
  ): string {
    if (query['id'] === 'self') {
      return this.buildURL(modelName, query['id'])
    }

    return super.urlForQueryRecord(query, modelName)
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    student: StudentAdapter
  }
}
